/* 
 * SIDEBAR MENU & SECTIONS
 */

export const mediaQuery = 'only screen and (min-width : 901px)';

export const triggers = [];

export function bootstrap() {
  const triggerEls = document.querySelectorAll('.js-sidebar-trigger');
  
  triggerEls.forEach(triggerEl => {
    const trigger = {
      trigger: triggerEl,
      section: document.getElementById(triggerEl.dataset.target)
    };

    trigger.callback = (e) => {
      e.preventDefault();
      switchTo(trigger);
    };

    triggers.push(trigger);

    triggerEl.addEventListener('click', trigger.callback);

    if (triggerEl.dataset.desktopDefault && triggerEl.dataset.desktopDefault === 'true') {
      switchTo(trigger);
    }
  });
}

export function destroy() {
  triggers.forEach(trigger => {
    trigger.trigger.classList.remove('c-link--active');
    trigger.section.classList.remove('c-sidebar__section--active');
    trigger.trigger.removeEventListener('click', trigger.callback);
  });

  while (triggers.length > 0) triggers.pop();
}

function switchTo(trigger) {
  trigger.trigger.classList.add('c-link--active');
  trigger.section.classList.add('c-sidebar__section--active');

  triggers.forEach(otherTrigger => {
    if (otherTrigger !== trigger) {
      otherTrigger.trigger.classList.remove('c-link--active');
    }

    if (otherTrigger.section !== trigger.section) {
      otherTrigger.section.classList.remove('c-sidebar__section--active');
    }
  });
}
