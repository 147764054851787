import * as images from './images';
import * as scroll from './scroll';
import * as darkMode from './dark-mode';
import * as gotoTop from './goto-top';
import * as accordions from './accordions';
import * as desktopSections from './desktop-sections';
import * as mobilePages from './mobile-pages';
import * as gliders from './gliders';
import * as overlay from './overlay';
import * as mobileMenu from './mobile-menu';

const modules = [
  overlay,
  images,
  scroll,
  darkMode,
  gotoTop,
  accordions,
  desktopSections,
  mobilePages,
  mobileMenu, // always after mobilePages
  gliders
];

modules.forEach(module => {
  if (module.init) {
    module.init();
  }
});

document.addEventListener('DOMContentLoaded', e => {
  // window.alert(document.body.clientWidth.toString());

  modules.forEach(module => {
    
    if (module.mediaQuery) {

      const mq = window.matchMedia(module.mediaQuery);

      if (mq.matches && module.bootstrap) {
        module.bootstrap();
      }

      mq.addEventListener('change', e => {
        if (e.matches && module.bootstrap) {
          module.bootstrap();
        } else if (!e.matches && module.destroy) {
          module.destroy();
        }
      });

    } else if (module.bootstrap) {
      module.bootstrap();
    }

  });
});
