/* 
 * GO TO TOP
 */

export function bootstrap() {
  const topTrigger = document.querySelector('.js-goto-top'),
        topTriggerTarget = document.getElementById(topTrigger.dataset.target);
  
  let topTriggerState = true,
      prevTopTriggerState = true;

  topTrigger.addEventListener('click', e => {
    topTriggerTarget.scrollTo(0, 0);
  });

  topTriggerTarget.addEventListener('scroll', e => {
    topTriggerState = topTriggerTarget.scrollTop <= 0;

    if (topTriggerState !== prevTopTriggerState) {
      requestAnimationFrame(() => {
        if (topTriggerState) {
          topTrigger.classList.add('o-apparition--hidden');
        } else {
          topTrigger.classList.remove('o-apparition--hidden');
        }
      });
    }

    prevTopTriggerState = topTriggerState;
  }, 'passive');
}
