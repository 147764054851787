/* 
 * CAROUSELS
 */

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export function bootstrap() {
  const gliderContainers = document.querySelectorAll('.js-glider'),
        gliders = {};
  
        window.gliders = gliders;

  gliderContainers.forEach(container => {    
    const pagination = {};
    if (container.dataset.hasOwnProperty('gliderDots')) {
      Object.assign(pagination, {
        el: '.c-glider__bullets',
        type: 'bullets',
        bulletClass: 'c-glider__bullet',
        bulletActiveClass: 'c-glider__bullet--active',
        bulletElement: 'button',
        clickable: true
      });
    }

    const g = new Swiper(container, {
      modules: [Navigation, Pagination],
      loop: true,
      navigation: {
        nextEl: '.c-glider__button--next',
        prevEl: '.c-glider__button--previous',
      },
      pagination 
    });

    if (container.dataset.id) {
      gliders[container.dataset.id] = g;
    }
  });

  const counterEls = document.querySelectorAll('.js-glider-counter');

  counterEls.forEach(counter => {
    if (counter.dataset.gliderTarget && gliders[counter.dataset.gliderTarget]) {
      const g = gliders[counter.dataset.gliderTarget];
      
      function updateCounter() {
        counter.textContent = (g.realIndex + 1).toString() + '/' + g.slides.length.toString();
      }

      g.on('slideChange', updateCounter);

      updateCounter();
    }
  });
}