/* 
 * MOBILE PAGES
 */

export const mediaQuery = '(max-width : 900px)';

export const triggers = [];
let isInitialized = false,
    defaultPage,
    lastTrigger;

export function bootstrap() {
  isInitialized = true;
  showDefaultPage();

  const triggerEls = document.querySelectorAll('.js-mobile-page-trigger');

  triggerEls.forEach(triggerEl => {
    registerTrigger(triggerEl);
  });
}

export function destroy() {
  isInitialized = false;
  lastTrigger = null;

  removeDefaultPage();

  triggers.forEach(trigger => {
    console.log(trigger.trigger.textContent);
    _destroyTrigger(trigger);
  });

  while (triggers.length > 0) triggers.pop();

  isInitialized = false;
}

export function registerTrigger(triggerEl) {
  if(!isInitialized) return;

  const trigger = {
    trigger: triggerEl,
    text: triggerEl.textContent,
    page: document.getElementById(triggerEl.dataset.target),
    back: (triggerEl.dataset.mobileBack && triggerEl.dataset.mobileBack === 'true'),
    isOpen: false
  };

  triggers.push(trigger);

  trigger.callback = (e) => {
    e.preventDefault();

    if (trigger.back && trigger.isOpen) {
      if (lastTrigger) {
        lastTrigger.open();
      } else {
        trigger.close();
        showDefaultPage();
      }
    } else {
      trigger.open();
    }

    if (!trigger.back) {
      lastTrigger = trigger;
    }
  };

  trigger.open = () => {
    trigger.isOpen = true;
    trigger.page.classList.add('o-mobile-page--visible');
    if (!trigger.back) {
      trigger.trigger.classList.add('c-link--active');
    }

    if (trigger.back && trigger.trigger.dataset.mobileAltText) {
      trigger.trigger.textContent = trigger.trigger.dataset.mobileAltText;
    }

    triggers.forEach(otherTrigger => {
      if (otherTrigger !== trigger) {
        otherTrigger.close(trigger.back);
      }
    });
  };

  trigger.close = (wasBackTrigger) => {
    trigger.isOpen = false;
    trigger.page.classList.remove('o-mobile-page--visible');
    if (!trigger.back && !wasBackTrigger) {
      trigger.trigger.classList.remove('c-link--active');
    }

    if (trigger.back && trigger.trigger.dataset.mobileAltText) {
      trigger.trigger.textContent = trigger.text;
    }
  };

  trigger.trigger.addEventListener('click', trigger.callback);

  return trigger;
}

// Private function which does not splice, since we can't splice while iterating
// over the array.
function _destroyTrigger(trigger) {
  const index = triggers.indexOf(trigger);

  if (index !== -1) {
    trigger.close();
    trigger.trigger.removeEventListener('click', trigger.callback);
  }

  return index;
}

export function destroyTrigger(trigger, backToDefault) {
  const index = _destroyTrigger(trigger);

  if (index === -1) return;

  triggers.splice(index, 1);

  if (backToDefault) {
    if (lastTrigger && lastTrigger !== trigger) {
      lastTrigger.open();
    } else {
      showDefaultPage();
    }
  }
}

function showDefaultPage() {
  defaultPage = document.querySelector('.o-mobile-page[data-mobile-default=true');

  if (defaultPage) {
    defaultPage.classList.add('o-mobile-page--visible');
  }
}

function removeDefaultPage() {
  if (defaultPage) {
    defaultPage.classList.remove('o-mobile-page--visible');
  }
}
