/*
 * HOMEPAGE OVERLAY
 */

let willShowOverlay = false;

export function init() {
  const navType = window.performance.getEntriesByType('navigation')[0].type;

  // console.log(navType, new URL(document.referrer).hostname, location.hostname);

  if (location.pathname === '/' &&
      (navType === 'navigate' || navType === 'reload') &&
      (document.referrer === '' || new URL(document.referrer).hostname !== location.hostname)
    ) {
    willShowOverlay = true;
  }
}

export function bootstrap() {
  if (!willShowOverlay) return;

  const overlay = document.querySelector('.c-overlay');

  if (overlay) {
    overlay.classList.add('c-overlay--enable');

    const callback = (e) => {
      overlay.classList.add('c-overlay--animate');
      overlay.removeEventListener('click', callback);
    };

    overlay.addEventListener('click', callback);

    const animationCallback = (e) => {
      overlay.classList.remove('c-overlay--enable', 'c-overlay--animate');
      overlay.removeEventListener('animationend', animationCallback);

      // window.history.pushState({}, '', '/projects');
    };

    overlay.addEventListener('animationend', animationCallback);  
  }
}
