/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */

import scrollIntoView from 'scroll-into-view-if-needed'

class Accordion {
  constructor(domNode) {
    this.buttonEl = domNode;

    const controlsId = this.buttonEl.getAttribute('aria-controls');
    this.contentEl = document.getElementById(controlsId);
    this.scrollEl = document.getElementById(domNode.dataset.scroll);

    this.open = this.buttonEl.getAttribute('aria-expanded') === 'true';
    this.onOpen = null;

    // add event listeners
    this.buttonEl.addEventListener('click', this.onButtonClick.bind(this));
    
    this.computeDuration = (height) => {
      return Math.max(600, height * 8/3);
      //return 800;
    }

    // this.ease = 'ease-in-out';
    this.ease = 'cubic-bezier(0.4, 0, 0.2, 1)'; // ease-in-out ?
  }

  onButtonClick() {
    this.toggle(!this.open);
  }

  toggle(open) {
    // don't do anything if the open state doesn't change
    if (open === this.open) {
      return;
    }

    // update the internal state
    this.open = open;

    // handle DOM updates
    this.buttonEl.setAttribute('aria-expanded', `${open}`);

    if (open) {
      // Opening
      const self = this;
      this.contentEl.classList.remove('o-accordion__panel--closed');
      this.contentEl.animate(
        [
          {height: '0px'},
          {height: this.contentEl.clientHeight.toString() + 'px'}
        ],
        {
          duration: this.computeDuration(this.contentEl.clientHeight),
          easing: this.ease
        }
      )
      .addEventListener('finish', () => {
          scrollIntoView(self.contentEl, {
            scrollMode: 'if-needed',
            block: 'end'
          });
      });

      if (this.onOpen) {
        this.onOpen.call(this);
      }
    } else {
      // Closing
      const self = this;
      this.contentEl.animate(
        [
          {height: this.contentEl.clientHeight.toString() + 'px'},
          {height: '0px'}
        ],
        {
          duration: this.computeDuration(this.contentEl.clientHeight),
          easing: this.ease
        }
      )
      .addEventListener('finish', () => {
        self.contentEl.classList.add('o-accordion__panel--closed');
      });
    }
  }

  // Add public open and close methods for convenience
  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }
}

export function bootstrap() {
  const accordionEls = document.querySelectorAll('.js-accordion'),
        accordions = [];

  accordionEls.forEach(accordionEl => {
    const accordion = new Accordion(accordionEl);
    accordions.push(accordion);

    // Close all other accordions in the same domain
    if (accordionEl.dataset.domain) {
      accordion.onOpen = () => {
        accordions.forEach(otherAccordion => {
          if (otherAccordion !== accordion &&
              otherAccordion.buttonEl.dataset.domain &&
              otherAccordion.buttonEl.dataset.domain === accordionEl.dataset.domain) {
            otherAccordion.close();
          }
        });
      };
    }
  });
}
