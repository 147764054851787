/* 
 * SCROLLBARS & DISAPPEARING TOPBAR
 */

export const mediaQuery = '(min-width : 901px)';

const columns = [];
let topbar;


export function bootstrap() {
  const columnsEl = document.querySelectorAll('.js-listen-scroll');
  topbar = document.querySelector('.js-topbar');

  columnsEl.forEach((columnEl) => {
    const column = {
      element: columnEl,
      scrollEndTimer: null
    };

    let isScrolling = false,
        prevScrollTop = 0,
        direction = false, // true: up, false: down
        prevDirection = true;
    
    const doHideTopbar = column.element.classList.contains('js-hide-topbar-on-scroll');


    column.scrollendCb = () => {
      column.element.classList.remove('js-is-scrolling');
      isScrolling = false;
    };


    column.onScrollCb = () => {
      // Is scrolling indicator CSS class
      if (!isScrolling) {
        isScrolling = true;

        requestAnimationFrame(() => {
          column.element.classList.add('js-is-scrolling');
        });
      }
      
      // Topbar mechanism
      if (doHideTopbar) {
        direction = (prevScrollTop > column.element.scrollTop);

        // We only react to direction changes *below* the top of the page,
        // ignoring bouncy scroll effects on Safari.
        if (direction !== prevDirection && column.element.scrollTop > 0) {
          requestAnimationFrame(() => {
            if (direction) {
              topbar.classList.remove('o-bar--hidden');
            } else {
              topbar.classList.add('o-bar--hidden');
            }
          });

          prevDirection = direction;
        }
      }

      // Scroll end delay
      // 'scrollend' event polyfill from
      // https://developer.chrome.com/blog/scrollend-a-new-javascript-event/
      // Used as fallback in all cases, because scrollend does not seem to work
      // well on some platforms.
      clearTimeout(column.scrollEndTimer);
      column.scrollEndTimer = setTimeout(column.scrollendCb, 100);

      prevScrollTop = columnEl.scrollTop;
    };

    column.element.addEventListener('scroll', column.onScrollCb, { 'passive': true });
    
    if ('onscrollend' in window) {
      column.element.addEventListener('scrollend', column.scrollendCb, { 'passive': true });
    }

    columns.push(column);
  });
}

export function destroy() {
  topbar.classList.remove('o-bar--hidden');

  columns.forEach(column => {
    column.element.removeEventListener('scroll', column.onScrollCb, { 'passive': true });

    column.scrollendCb();

    if ('onscrollend' in window) {
      column.element.removeEventListener('scrollend', column.scrollendCb, { 'passive': true });
    }
  })
}