/* 
 * DARK MODE
 */

export function init() {
  if(localStorage.getItem('theme') === 'dark') {
    document.documentElement.classList.add('dark-mode');
  } else if(localStorage.getItem('theme') === 'light') {
    document.documentElement.classList.remove('dark-mode');
  }
}

export function bootstrap() {
  const dmToggle = document.querySelector('.js-dark-mode-toggle');
  dmToggle.addEventListener('change', function() {
    if (dmToggle.checked) {
      document.documentElement.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark-mode');
      localStorage.setItem('theme', 'light');
    }
  });
}
