/* 
 * IMAGE LAZY LOADING
 */

export function bootstrap() {
  const images = document.querySelectorAll('.js-lazy');
  
  images.forEach(image => {
    if (image.complete) {
      image.classList.remove('o-apparition--hidden');
    } else {
      image.addEventListener('load', e => {
        image.classList.remove('o-apparition--hidden');
     });
    }
  });
}