/*
 * MOBILE MENU
 */

import { registerTrigger, destroyTrigger } from "./mobile-pages";

export const mediaQuery = '(max-width: 640px)';

let triggerEl,
    trigger;

export function bootstrap() {
  triggerEl = document.querySelector('.js-mobile-menu-trigger');
  trigger = registerTrigger(triggerEl);
}

export function destroy() {
  if (trigger) {
    destroyTrigger(trigger, true);
  }
}
